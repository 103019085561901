<template>
  <v-autocomplete
    v-model="value"
    v-model:search="queryModel"
    class="app-form-autocomplete"
    :clearable="clearable"
    v-bind="bind"
    closable-chips
    :item-title="itemTitle"
    :item-value="itemValue"
    :items="items"
    :loading="loading"
    :menu="menu"
    :multiple="multiple"
    :no-data-text="noDataText"
    :no-filter="!autoFilter"
    :return-object="returnObject"
  >
    <template v-if="$slots.selection" #chip="{ props, item }">
      <slot name="selection" v-bind="{ props, item: item.raw }" />
    </template>

    <template #item="{ props, item, index }">
      <div
        v-intersect.once="index === items.length - 1 ? onIntersect : () => {}"
        data-cy-autocomplete-item
        :data-cy-autocomplete-item-id="get(item.raw, itemValue ?? '')"
      >
        <slot
          name="item"
          v-bind="{ item: item.raw, props: omit(props, 'title') }"
        >
          <v-list-item v-bind="props" />
        </slot>
      </div>
    </template>

    <template v-if="$slots['no-data']" #no-data>
      <slot name="no-data" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts" setup>
import get from "lodash/get";
import omit from "lodash/omit";

import { appFormInputProperties } from "../composables/form-input.hook";

const properties = defineProps({
  ...appFormInputProperties,
  modelValue: { type: [Object, Number, String], default: undefined },
  query: { type: String, default: undefined },
  items: { type: Array, default: () => [] },
  itemTitle: { type: String, default: undefined },
  itemValue: { type: String, default: undefined },
  multiple: { type: Boolean, default: false },
  autoFilter: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  noDataText: { type: String, default: undefined },
  returnObject: { type: Boolean, default: true },
  menu: { type: Boolean, default: false },
});

defineSlots<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection(props: { item: any; props: any }): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item(props: { item: any; props: any }): void;
  "no-data"(): void;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", payload: string): void;
  (event: "update:query", payload: string): void;
  (event: "scroll-end"): void;
}>();

const { value, bind } = useAppFormInput(properties);
const queryModel = ref("");

watchDebounced(
  queryModel,
  (value) => {
    emit("update:query", value ?? "");
  },
  { debounce: 150 },
);

const onIntersect = (isIntersecting: boolean) => {
  if (!isIntersecting) return;

  emit("scroll-end");
};
</script>

<style lang="scss" scoped>
.app-form-autocomplete {
  :deep(.v-field__input) {
    gap: 5px;
  }

  :deep(.v-field--dirty .v-autocomplete__selection) {
    margin-inline-end: 0;
  }
}
</style>
